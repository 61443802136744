import './sheet.css';
import { BtnLogout } from '../ui/BtnLogout';
import { BtnBack } from '../ui/BtnBack';
import { useForm } from '../../hooks/useForm';
import { useDispatch } from 'react-redux';
import { filterDownloadSheet } from '../../actions/sheet';
import { DownloadOutlined } from '@ant-design/icons';

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>


export const SheetScreen = ( {history} ) => {

    const dispatch = useDispatch();

    const [ formSheetValues, handleSheetInputChange ] = useForm({
            lem: '',
            deliverynote:'HOJA DESCARGA',
        });

    let { lem } = formSheetValues;

        const handleSheetDownload = async (e) => {

            e.preventDefault();

            await dispatch( filterDownloadSheet(formSheetValues) );
        }

    return (
         <div>
            <section id="general-hdr">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <BtnBack history={history}/>
                            <h1 className="section-name">Hoja Recepción</h1>
                        </div>
                        <div className="col-auto"><BtnLogout/></div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 register-form-2">
                        <form onSubmit={ handleSheetDownload } className="mmk-form">
                            <div className="row mb-2">
                                <div className="col">
                                    <label className="form-label" >LEM</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Lem"
                                        name="lem"
                                        value= { lem }
                                        onChange= { handleSheetInputChange }
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end mb-2">
                                <div className="col-12 ">
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary margin-top"><DownloadOutlined />Descargar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}