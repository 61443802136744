import { fetchConToken, fetchDocuments } from "../helpers/fetch";
import { types } from "../types/types";
import { startLogout } from "./auth";
import Swal from "sweetalert2";

export const deliveryStartLoading = (id) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`stockmovement/`);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( deliveryLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

const deliveryLoaded = (delivery) => ({
    type: types.deliveryLoaded,
    payload: delivery
})

export const filterDeliveryDonwload = (data) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`deliverynote/downloads`, data);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( deliveryLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

const deliveryNumberLoaded = (deliveryN) => ({
    type: types.deliveryNLoaded,
    payload: deliveryN
})


export const filterDeliveryDonwloadN = (data) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`deliverynote/ndownloads`, data);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( deliveryNumberLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

const deliveryExpeditionLoaded = (deliveryE) => ({
    type: types.deliveryExpeditionLoaded,
    payload: deliveryE
})

export const filterDeliveryExpedition= (data) => {

    return async( dispatch, getState ) => {

        try{
            const resp = await fetchConToken(`deliverynote/expeditions`, data);
            const body = await resp.json();
            if ( resp.status === 200 && body){
                dispatch( deliveryExpeditionLoaded(body) )
            }else {
                if (body?.token === false){
                    dispatch(startLogout())
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export const filterDownload = (data) => {
    return async( dispatch ) => {
        const resp = await fetchDocuments(`deliverynote/pdf`, data, () => dispatch(startLogout()));
        if( Array.isArray(resp.documents) && resp.documents.length === 0 ){
            const msg = 'No hay archivo disponible para descargar.';
            Swal.fire('Descarga', msg, 'info');
        }
    }
}

export const filterDownloads = (deliverys) => {
    return async( dispatch ) => {
        try{
            for (let item of deliverys.data) {
                const data = {
                    store: undefined,
                    deliverynote: item.deliverynote
                }
                await dispatch(filterDownload(data));
            }
        } catch (error) {
            console.log(error)
        }
    }
}